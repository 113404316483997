<template>
  <div class="totalizer-list">
    <div class="integral-title">
      <span>积分兑换</span>
      <span>兑换帮您省钱</span>
    </div>
    <div class="intearal-con">
      <div class="intearal-item"  @click="goChange(item)" v-for="(item, idx) in integralArr" :key="idx">
        <div class="intearal-item-top">
          <div>
            <img v-lazy="item.pic"/>
          </div>
          <span>{{item.title}}</span>
        </div>
        <div class="intearal-item-jifen"><span class="iconfont iconjifen1"></span> {{item.credit}}</div>
        <!-- <div class="intearal-item-btn">
          <span>兑换</span>
        </div> -->
        <div class="intearal-item-btn">
          <span>兑换</span>
        </div>
      </div>
    </div>
    <p @click="load_list"> <icon name="replay" /> 换一批</p>
  </div>
</template>
<script>
import { icon } from "vant";
import CreditShop from "@/api/creditshop";
export default {
  data() {
    return {
      page: 1,
      integralArr: []
    }
  },
  components: {
    icon
  },
  mounted() {
    this.$nextTick(() => {
      this.load_list();
    })
  },
  methods: {
    goChange(item) {
      this.$router.push('/integraldetail?id=' + item.id);
    },
    async load_list() {
      try {
        const res = await CreditShop.getCreditshopList(this.page++);
        if (res.code == 200) {
          if (res.data.result.length > 0) {
            this.integralArr = res.data.result;
          } else {
            this.page = 1;
            throw('暂无更多...')
          }
        } else {
          throw(res.msg);
        }
      } catch (error) {
        this.$toast(error);
      }
    }
  },
  beforeDestroy() {
    this.page = 1;
  }
}
</script>
<style lang="scss" scoped>
.totalizer-list {
  width: 96%;
  height: auto;
  background: #ffffff;
  margin: 10px auto;
  .totalizer-item {
    width: 100%;
    height: 80px;
    display: flex;
    padding: 0 10px;
    font-size: 14px;
    align-items: center;
    border-bottom: 1px solid #dfdfdf;
    justify-content: space-between;
    .totalizer-item-left {
      height: 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      span:nth-child(1) {
        color: #000000;
      }
      span:nth-child(2) {
        color: #696969;
      }
    }
    .integral {
      color: #ff5954;
      font-size: 18px;
    }
  }
  p {
    height: 30px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .integral-title {
    width: 100%;
    height: 30px;
    color: #000000;
    font-size: 14px;
    padding: 0 10px;
    line-height: 30px;
    display: flex;
    justify-content: space-between;
    span:nth-child(2) {
      font-size: 12px;
      color: #696969;
    }
  }
  .intearal-con {
    width: 100%;
    height: auto;
    display: flex;
    padding: 0 10px;
    flex-wrap: wrap;
    margin-left: -10px;
    .intearal-item {
      width: 30%;
      height: 160px;
      display: flex;
      margin-top: 6px;
      margin-left: 10px;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      background:linear-gradient(0deg,rgba(255,233,233,1) 0%,rgba(255,234,234,1) 100%);
      border-radius:10px;
      .intearal-item-top {
        width: 80%;
        height: 90px;
        margin: 10px auto 0;
        background-color: #fff;
        display: flex;
        padding: 4px 0;
        border-radius: 6px;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        div {
          width: 60px;
          height: 60px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .intearal-item-jifen {
        color: rgba(253,69,51,1);;
        span {
          font-size: 24px;
          color: rgba(255,231,71,1);
        }
      }
      .intearal-item-btn, .intearal-item-twobtn {
        width: 60px;
        height: 24px;
        color: #ffffff;
        line-height: 24px;
        text-align: center;
        border-radius: 30px;
        margin-bottom: 6px;
        background: rgba(253,69,51,1);
      }
      .intearal-item-twobtn {
        background-color: #696969;
      }
    }
  }
}
</style>