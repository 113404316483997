<template>
	<div class="sign-con">
		<publicTop TopText="签到中心" :back_status="true"></publicTop>
		<div class="sign-main">
			<div class="sign-main-title">
				<span class="iconfont iconqiandao"></span>
				<span>签到处</span>
			</div>
			<div class="sign_cont">
				<div class="sign_cont_operation">
					<span>已签到{{ sign_info.orderday }}天</span>
					<Button
						round
						type="info"
						size="small"
						color="#FF914B"
						@click="userSignIn(sign_info.orderday)"
						>签到领取</Button
					>
				</div>
				<div class="sign_step">
					<div
						class="sign_step_item"
						v-for="(item, index) in checkInArr"
						:key="index"
					>
						<div class="_item" :class="item.status ? '_item_on' : ''">
							{{ item.day }}
						</div>
						<div
							class="line"
							v-if="item.day != 7"
							:class="item.status ? '_item_on' : ''"
						></div>
						<div class="hint" :class="item.status ? 'color_on' : ''">
							<span>+</span>
							<span v-text="parseInt(item.credit)"></span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="totalizer">
			<div>
				<img src="@static/vip/love letter.png" />
			</div>
			<div class="totalizer-text">
				<span
					>您已累计签到
					<span class="num-day">{{ sign_info.sum }}</span> 天</span
				>
				<span>据说累计签到七天有超额积分赠送哦...</span>
			</div>
		</div>
		<div class="totalizer-list">
			<div class="totalizer-item" v-for="(item, idx) in listArr" :key="idx">
				<div class="totalizer-item-left">
					<span>签到奖励</span>
					<span>{{ item.create_time }}</span>
				</div>
				<div class="integral">+ {{ item.credit }}</div>
			</div>
			<router-link to="/integral">
				<p style="color:#000">查看更多</p>
			</router-link>
		</div>
		<exchangeItem></exchangeItem>
	</div>
</template>
<script>
import CreditShop from "@/api/creditshop";
import UserInfo from "@/api/user";
import exchangeItem from "./child/exchangeItem";
import publicTop from "./child/publicTop";
import { Button } from "vant";
export default {
	components: {
		publicTop,
    exchangeItem,
    Button
	},
	data() {
		return {
			checkInArr: [],
			sign_info: {
        sum: 0,
        orderday: 0,
        credit: 0
      },
			listArr: [],
			page: 0,
			check_Info: {
				day: "",
				credit: ""
			}
		};
	},
	created() {
		this.getCheckList();
		this.getUserSignList();
	},
	methods: {
		async getCheckList() {
			try {
				const res = await CreditShop.getSignCycle();
				if (res.code == 200) {
					if (res.data.result.length > 0) {
						this.checkInArr = res.data.result;
						if (res.data.sign_info == "" || res.data.sign_info == null) {
							throw "暂无签到内容";
						} else {
							this.sign_info = res.data.sign_info;
						}
					} else {
						throw "无数据...";
					}
				} else {
					throw res.msg;
				}
			} catch (error) {
				console.log(error);
			}
		},
		async userSignIn(item) {
			try {
				let day = item + 1;
				let credit = this.checkInArr[item].credit;
				const res = await CreditShop.userCheckIn(day, credit);
				if (res.code == 200) {
					this.$toast(res.msg);
					// 更新
					this.getCheckList();
				} else {
					throw res.msg;
				}
			} catch (error) {
				this.$toast(error || "网络错误");
			}
		},
		// 积分列表
		async getUserSignList() {
			try {
				const res = await UserInfo.Sign_Records();
				if (res.code == 200) {
					this.listArr = res.data.result;
				}
			} catch (error) {
				console.log(error);
			}
		}
	}
};
</script>
<style lang="scss" scoped>
$bgcolor: rgba(244, 244, 244, 1);
.sign-con {
	width: 100%;
	height: 100vh;
	overflow-y: scroll;
	background-color: $bgcolor;
	.sign-main {
		width: 96%;
		height: auto;
		margin: 0 auto;
		z-index: 1000;
		margin-top: -20px;
		padding-bottom: 10px;
		border-radius: 10px;
		background-color: #ffffff;
		.sign-main-title {
			width: 100%;
			height: 40px;
			line-height: 40px;
			padding-top: 10px;
			padding-left: 20px;
			span {
				color: #ff5954;
				font-size: 20px;
			}
			span:nth-child(2) {
				font-size: 14px;
				margin-left: 10px;
			}
		}
		.sign_cont {
			margin: 0 auto;
			width: 347px;
			height: 114px;
			background: rgba(255, 255, 255, 1);
			border-radius: 8px;
			padding: 10px;
			overflow: hidden;
		}
		.sign_cont_operation {
			height: 30px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			font-size: 14px;
		}
		.sign_step {
			padding: 0 10px;
			margin-top: 20px;
			min-height: 40px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin-right: -30px;
			.sign_step_item {
				flex: 1;
				position: relative;
				._item {
					width: 16px;
					height: 16px;
					background: #d8d8d8;
					border-radius: 50%;
					text-align: center;
					line-height: 16px;
					color: #fff;
					font-weight: 400;
					z-index: 2;
					position: relative;
				}
				.line {
					position: absolute;
					width: 100%;
					height: 2px;
					background: #d8d8d8;
					top: 7px;
				}
				.hint {
					margin-top: 4px;
					position: absolute;
					left: -5px;
					color: #d8d8d8;
				}
				._item_on {
					background: #ff914b;
				}
				.color_on {
					color: #ff914b;
				}
			}
		}
		.sign-main-con {
			width: 100%;
			height: 100px;
			display: flex;
			.sign-main-con-item {
				flex: 1;
				padding-top: 20px;
				height: 80px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-direction: column;
				background-color: #fff;
				span:nth-child(2) {
					color: #696969;
					font-size: 20px;
				}
				span:nth-child(2) {
					color: #ff5954;
					font-size: 20px;
				}
			}
		}
		.sign-btn {
			width: 100%;
			display: flex;
			justify-content: center;
			button {
				width: 120px;
				height: 40px;
			}
		}
	}
	.totalizer {
		width: 96%;
		height: 80px;
		margin: 0 auto;
		margin-top: 10px;
		border-radius: 6px;
		padding: 10px 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: #fff;
		div {
			img {
				width: 50px;
				height: 50px;
			}
		}
		.totalizer-text {
			width: 84%;
			height: 50px;
			display: flex;
			padding: 4px 10px;
			justify-content: space-between;
			flex-direction: column;
			.num-day {
				font-size: 16px;
				font-weight: 500;
				color: #ff5954;
			}
		}
	}
	.totalizer-list {
		width: 96%;
		height: auto;
		background: #ffffff;
		margin: 10px auto;
		.totalizer-item {
			width: 100%;
			height: 80px;
			display: flex;
			padding: 0 10px;
			font-size: 14px;
			align-items: center;
			border-bottom: 1px solid #dfdfdf;
			justify-content: space-between;
			.totalizer-item-left {
				height: 40px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				span:nth-child(1) {
					color: #000000;
				}
				span:nth-child(2) {
					color: #696969;
				}
			}
			.integral {
				color: #ff5954;
				font-size: 18px;
			}
		}
		p {
			height: 30px;
			font-size: 14px;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
		}
		.integral-title {
			width: 100%;
			height: 30px;
			color: #000000;
			font-size: 14px;
			padding: 0 10px;
			line-height: 30px;
			display: flex;
			justify-content: space-between;
			span:nth-child(2) {
				font-size: 12px;
				color: #696969;
			}
		}
		.intearal-con {
			width: 100%;
			height: auto;
			display: flex;
			padding: 0 10px;
			flex-wrap: wrap;
			justify-content: flex-start;
			.intearal-item {
				width: 30%;
				height: 160px;
				display: flex;
				margin-top: 6px;
				flex-direction: column;
				align-items: center;
				justify-content: space-between;
				background: linear-gradient(
					0deg,
					rgba(255, 233, 233, 1) 0%,
					rgba(255, 234, 234, 1) 100%
				);
				border-radius: 10px;
				.intearal-item-top {
					width: 80%;
					height: 90px;
					margin: 10px auto 0;
					background-color: #fff;
					display: flex;
					padding: 4px 0;
					border-radius: 6px;
					align-items: center;
					justify-content: space-between;
					flex-direction: column;
					div {
						width: 60px;
						height: 60px;
						img {
							width: 100%;
							height: 100%;
						}
					}
				}
				.intearal-item-jifen {
					color: rgba(253, 69, 51, 1);
					span {
						font-size: 24px;
						color: rgba(255, 231, 71, 1);
					}
				}
				.intearal-item-btn,
				.intearal-item-twobtn {
					width: 60px;
					height: 24px;
					color: #ffffff;
					line-height: 24px;
					text-align: center;
					border-radius: 30px;
					margin-bottom: 6px;
					background: rgba(253, 69, 51, 1);
				}
				.intearal-item-twobtn {
					background-color: #696969;
				}
			}
		}
	}
}
</style>
